import React from "react";
import "./Faq.scss";
import { Question } from "./Question";

const data = [
  {
    question: 'MinプランとProプランの違いは何ですか？',
    answer: 'プロジェクト管理での機能的な違いはありませんが、 Proプランではログインできるユーザを複数人設定することができます。Minプランではログインできるユーザは1アカウントのみです。'
  },
  {
    question: 'Proプランのユーザごとの権限にはどういったものがありますか？',
    answer: '管理ユーザや作業ユーザの他に、他社の作業者を<b>外部提携者</b>として招待することができます。詳細はScreewドキュメントの<a href="https://d.scrw.jp/function/users/authority/" target="_blank">権限について</a>をご参照ください。'
  },
  {
    question: 'バックアップについて',
    answer: 'サーバの物理障害に備え、運営側で定期バックアップを行っています。'
  },
  {
    question: '容量について',
    answer: 'ストレージ容量には、OS及びScreewシステムの容量も含まれるため、契約した容量全てが使用できるわけではありません。また、Gitの性質上、コミットの仕方によってはかなりの容量を消費することがあります。<br>スケールアップも対応しておりますので、お気軽にお問い合わせください。'
  },
]

const Faq = () => {

	return (
		<section className="sec_faq">
			<div className="inner">
        <h2 className="sec_title" id="sec_faq">よくある質問</h2>
        <ul>
          {data.map((d, index) => (
            <Question key={index} index={index + 1} question={d.question} answer={d.answer} />
          ))}
        </ul>
			</div>
		</section>
	);
};

export default Faq;
