import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const useOnScreen = () => {
	const refs = useRef([]);
	refs.current = [];

	const addToRefs = (el) => {
		if (el && !refs.current.includes(el)) {
			refs.current.push(el);
		}
	};

	useEffect(() => {
		refs.current.forEach((el, index) => {
			gsap.from(el, {
        duration: 1,
        delay: index / 9,
				opacity: 0,
        ease: "power4.out",
        y: 60,
				scrollTrigger: {
					trigger: el,
					start: "top center+=200",
        },
      });      
		});
	}, []);

	return [addToRefs];
};
