import React from 'react'
import { useOnScreen } from '../../hooks/useOnScreen';
import About01 from "../../img/about01.png"
import About02 from "../../img/about02.png"
import About03 from "../../img/about03.png"
import "./About.scss";


const data = [
  {
    label: "Environment",
    title: "サブドメインのURLが<br>自動で発行されるテスト環境",
    body: "Screewにプロジェクトを登録するだけで、サブドメインのURLが自動で発行され、そのままWebサイトのテスト環境として使用することができます。サブドメイン用にDNSレコードを追加したり、ポート開放をする必要はありません。",
    img: About01
  },
  {
    label: "Git Repository",
    title: "Gitリポジトリを利用して<br>複数人での開発が可能",
    body: "プロジェクトごとにGitリポジトリが用意されるので、バージョン管理・複数人での開発が行えます。コミットしたデータはそのままテスト環境にデプロイされるので、従来のFTPを使ったファイル転送から開放されます。",
    img: About02
  },
  {
    label: "Storage",
    title: "データ受け渡し場所に使える<br>ストレージ",
    body: "ファイルの送信用として、プロジェクトごとにデータストレージが用意されます。外部ストレージサービスを使わずに、開発から納品までScreewで完結させることができます。",
    img: About03
  }
]


const About = () => {
  const [addToRefs] = useOnScreen()

  return (
    <section className="sec_about" id="sec_about">
        {data.map((d, index) => (
          <div className="flex_wrap" key={index} ref={addToRefs}>
            <div className="img"><img src={d.img} alt="" /></div>
            <div className="text">
              <span className="sub01">{d.label}</span>
              <h3 className="title01 text_grade" dangerouslySetInnerHTML={{ __html: d.title }}></h3>
              <p>{d.body}</p>
            </div>
          </div>
        ))}
    </section>

  )
}

export default About
