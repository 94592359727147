import { useState, useRef } from "react";


export const useForm = () => {
  const [values, setValues] = useState({
    type: "資料請求",
		name: "",
		email: "",
		tel: "",
		company: "",
		content: "",
		privacy: false,
		recaptcha: ""
	});
	const [errors, setErrors] = useState({
		name: "",
		email: "",
		tel: "",
		content: ""
	});
	const [isDisabled, setIsDisabled] = useState(true)
	const recaptchaRef = useRef(null)



	const validate = (id, value) => {
		const reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;
		const telReg = /^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/;
		if (id === "name") {
			if (!value) {
				setErrors({ ...errors, [id]: "名前を入力してください" })
			} else if (value.length <= 1) {
				setErrors({ ...errors, [id]: "1文字以上入力してください" })
			} else {
				setErrors({ ...errors, [id]: "" })
			}

		} else if (id === "email") {
			if (!value) {
				setErrors({ ...errors, [id]: "メールアドレスを入力してください" })
			} else if (!reg.test(value)) {
				setErrors({ ...errors, [id]: "正しい形式で入力してください" })
			} else {
				setErrors({ ...errors, [id]: "" })
			}

		} else if (id === "tel") {
			const tel = value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'');
			if (!tel) {
				setErrors({ ...errors, [id]: "電話番号を入力してください" })
			} else if (!telReg.test(tel)) {
				setErrors({ ...errors, [id]: "正しい形式で入力してください" })
			} else {
				setErrors({ ...errors, [id]: "" })
			}

		} else if (id === "content") {
			if (!value) {
				setErrors({ ...errors, [id]: "お問い合わせ内容を入力してください" })
			} else if (value.length <= 1) {
				setErrors({ ...errors, [id]: "1文字以上入力してください" })
			} else {
				setErrors({ ...errors, [id]: "" })
			}
		} else {
			return errors;
		}
	};


	const handleChange = (e) => {
		const { id, value } = e.target;
		if (id === "privacy") {
			setValues({
				...values,
				[id]: !values.privacy,
			});
		} else{
			setValues({
				...values,
				[id]: value,
			});
		}
		validate(id, value)
	};

	const handleRecaptcha = value => {
		if (value) {
			setValues({
				...values,
				recaptcha: value
			});
		} 
	}

	const expiredRecaptcha = () => {
		setValues({
			...values,
			recaptcha: ""
		});
	}

	const resetRecaptcha = () => {
		recaptchaRef.current.reset();
		setValues({
			...values,
			recaptcha: ""
		});
	}


	const checkIsDisabled = () => {
		if (!errors.name && !errors.email && !errors.tel && !errors.content && values.name && values.email && values.tel && values.content && values.privacy && values.recaptcha ) {
			setIsDisabled(false)
		} else {
			setIsDisabled(true)
		}
	}

	const postForm = async () => {
		const postData = new FormData(); 
		for (let key in values) {
			postData.set(key, values[key])
		}		
		
		const data = {
			method: "POST",
			body: postData
		}

		const res = await fetch("form/form.php", data)
		const result = await res.json()
		console.log(result);
		result.forEach(res => {
			if (res.msg === "success") {
				window.location.href = "/thanks"
			} else {
				resetRecaptcha()
				alert("送信できませんでした。\n入力を確認してください")
			}
		})
	}


	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isDisabled) {
			await postForm()
		} else {
			resetRecaptcha()
			alert("項目を見直してください。")
		}
	};

	return { handleChange, handleSubmit, values, errors, isDisabled, checkIsDisabled, handleRecaptcha, expiredRecaptcha, recaptchaRef };
};

