import React, { useEffect } from "react";
import { Helmet } from "react-helmet"
import { useOnScreen } from "../hooks/useOnScreen";

const Law = () => {
  const [addToRefs] = useOnScreen();
  useEffect(() => {
    window.gtag("config", process.env.TRACKING_ID, { page_path: "/law" });
  }, [])

	return (
		<>
			<Helmet>
				<title>特定商取引法に基づく表記 | Screew | Web制作のプロジェクト進行をスムーズにするツール</title>
			</Helmet>
			<section className="p_tokuteishou">
				<div className="inner">
					<h2 className="sec_title" ref={addToRefs}>特定商取引法に基づく表記</h2>
					<div className="body" ref={addToRefs}>
						<section>
							<table>
								<tr>
<th>販売事業者名</th><td>株式会社Aluco</td>
</tr>
<tr>
<th>運営責任者名</th>	<td>大倉　竹次</td>
</tr>
<tr>
<th>本社所在地</th>	<td>〒619-0214 京都府木津川市木津宮ノ内65-3</td>
</tr>
<tr>
<th>電話番号</th>	<td>06-6537-9801 (お電話でのお問い合わせは受け付けておりません。メールにてお問い合わせ下さい)</td>
</tr>
<tr>
<th>メールアドレス</th>	<td>scrw@aluco.jp</td>
</tr>
<tr>
<th>販売価格</th>	<td><a href="/#sec_plan">料金プラン</a>に記載しています</td>
</tr>
<tr>
<th>お支払い方法</th>	<td>クレジットカード決済、銀行振り込み、請求書払い、自動口座振替</td>
</tr>
<tr>
<th>商品の引き渡し時期</th>	<td>お申し込み後、2、3日程度</td>
</tr>
<tr>
<th>返品・キャンセル</th>	<td>・お試し期間中のみキャンセルが可能です。<br />・本契約後は商品特性上、返金・キャンセルについてはお受けできません。<br />・解約手続きは、お問い合わせフォームよりご連絡ください。<br />・次回決済日までに解約をしていただければ、次回以降の請求は発生いたしません。<br /> 尚、日割り分等での返金は承っておりませんのでご了承ください。</td>
</tr>
</table>




						</section>
						</div>
				</div>
				</section>
			</>
	);
};

export default Law;
