import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Function, Form, MainVisual, Faq, Plan, About } from "../components";

const Home = () => {
  useEffect(() => {
    window.gtag("config", process.env.TRACKING_ID, { page_path: "/" });
  }, [])

	return (
		<>
			<Helmet>
				<title>Screew | Web制作のプロジェクト進行をスムーズにするツール</title>
			</Helmet>
			<MainVisual />
			<About />
			<Function />
			<Plan />
			<Faq />
			<Form />
		</>
	);
};

export default Home;
