import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { ReactComponent as NewWindow } from "../../img/icon_new-window.svg";
// import { Link as ScLink } from "react-scroll";
import { HashLink } from "react-router-hash-link";

const Header = () => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const scrollWidthOffset = (el) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -100;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
	};

	return (
		<header className={open ? "c_header isOpen" : "c_header"}>
			<div className="wrapper">
				<div className="logo">
					<Link to="/">
						<Logo />
					</Link>
				</div>
				<nav className={open ? "nav isOpen" : "nav"}>
					<div className="sp nav_logo">
						<Logo />
					</div>
					<HashLink
						className="nav-item"
						to="/#sec_about"
						scroll={(el) => scrollWidthOffset(el)}
						onClick={handleClick}
					>特徴
					</HashLink>
					<HashLink
						className="nav-item"
						to="/#sec_function"
						scroll={(el) => scrollWidthOffset(el)}
						onClick={handleClick}
					>機能
					</HashLink>
					<HashLink
						className="nav-item"
						to="/#sec_plan"
						scroll={(el) => scrollWidthOffset(el)}
						onClick={handleClick}
					>プラン
					</HashLink>
					<HashLink
						className="nav-item"
						to="/#sec_faq"
						scroll={(el) => scrollWidthOffset(el)}
						onClick={handleClick}
					>よくある質問
					</HashLink>
					<HashLink
						className="btn_cta outline"
						to="/#sec_form"
						scroll={(el) => scrollWidthOffset(el)}
						onClick={handleClick}
					>お問い合わせ
					</HashLink>
					<HashLink
						className="btn_cta"
            to="/#sec_form"
						scroll={(el) => scrollWidthOffset(el)}
						// target="_blank"
						// rel="noopener noreferrer"
						onClick={handleClick}
					>お申し込み
					</HashLink>
          <div className="sub_nav">
						<h1 className="common_head">ScreewはWeb制作のプロジェクト進行を<br className="sp" />スムーズにするツールです</h1>
						<nav>
							<Link to="/flow" onClick={handleClick}>ご利用までの流れ</Link>
							<Link
								className=""
								to="//d.scrw.jp"
								target="_blank"
								rel="noopener noreferrer"
								onClick={handleClick}
							>ドキュメント
								<NewWindow className="new-window" />
							</Link>
						</nav>
					</div>
				</nav>
				<div className={open ? "hambuger isOpen" : "hambuger"} onClick={handleClick}>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</header>
	);
};

export default Header;
