import React from "react";
import { useOnScreen } from "../../hooks/useOnScreen";
import { ReactComponent as Feature01 } from "../../img/feature01.svg";
import { ReactComponent as Feature02 } from "../../img/feature02.svg";
import { ReactComponent as Feature03 } from "../../img/feature03.svg";
import "./Function.scss";


const data = [
	{
		title: "自動デプロイ",
		img: Feature01,
		text: "Gitリポジトリのmasterブランチにプッシュすることで、自動的にテスト環境へファイルがデプロイされます。",
		caution: "",
	},
	{
		title: "ユーザ管理",
		img: Feature02,
		text: "社内のユーザはもちろん、外注先やクライアントの担当者を招待して、プロジェクトに参加させることもできます。",
		caution: "※Proプランのみ",
	},
	{
		title: "アクセス制限",
		img: Feature03,
		text: "プロジェクトごとにBASIC認証を設定して、テスト環境やストレージにアクセス制限をかけることができます。",
		caution: "",
	},
];

const Function = () => {
  const [addToRefs] = useOnScreen()


	return (
		<section className="sec_function">
			<div className="inner">
				<h2 className="sec_title" id="sec_function">
					Screewの機能
				</h2>
				<ul>
					{data.map((data, index) => (
						<li key={index} ref={addToRefs} className="card">
							<span className="index font_osw">{index + 1}</span>
							<p className="title">{data.title}</p>
							<div className="icon">
								<data.img />
							</div>
							<p className="text">{data.text}</p>
							{data.caution ? <p className="text">{data.caution}</p> : ""}
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default Function;
