import React from "react";
import { useOnScreen } from "../../hooks/useOnScreen";
import "./Flow.scss";

import visa from "../../img/card_visa.jpg"
import master from "../../img/card_master.jpg"
import jcb from "../../img/card_jcb.jpg"
import amex from "../../img/card_amex.jpg"
import diners from "../../img/card_diners.jpg"
import discover from "../../img/card_discover.jpg"



const Flow = () => {
  const [addToRefs] = useOnScreen();

	return (
		<section className="p_flow">
			<div className="inner">
        <h2 className="sec_title" ref={addToRefs}>ご利用までの流れ</h2>
        
        <section className="service" ref={addToRefs}>
          <h3 className="text_grade">サービス開始までの流れ</h3>
          <div className="step">
            <ul>
              <li>
                <h4>ご利用申し込み</h4>
                <p>お申し込みフォームから必要情報を入力してください。</p>
              </li>
              <li>
                <h4>セットアップ</h4>
                <p>セットアップが完了しましたらご登録メールアドレスにお知らせいたします。<br/>※Screewの初期セットアップは2〜3営業日かかります。</p>
              </li>
              <li>
                <h4>お試し無料期間</h4>
                <p>セットアップ後、2週間は無料でお試しいただけます。</p>
              </li>
              <li>
                <h4>お支払い</h4>
                <p>クレジットカードを選択されたお客様はお試し無料期間終了後、お支払い確定となります。<br/>銀行振込、ご請求書を選択されたお客様はお試し無料期間終了までに初月分をお支払いください。</p>
              </li>
              <li>
                <h4>サービス利用開始</h4>
                <p>お試し無料期間終了後、本契約となります。</p>
              </li>
            </ul>
          </div>

          <div className="notice">
            <h3>お申し込みについて</h3>
            <p className="">サイト上のお申し込みボタンからお申し込みください。</p>
            <p>無料お試し期間の2週間終了時点で本サービス開始（本登録）となります。</p>
            <p><b>お申し込みからご利用まで、Screewのセットアップに2〜3営業日いただいております。</b></p>
          </div>
        </section>

        <section className="payment" ref={addToRefs}>
          <h3 className="text_grade">お支払い方法</h3>
          <p>お支払い方法は、<b>クレジットカード、銀行振込、請求書払い、自動口座振替</b>の4種類からお選びいただけます。</p>
          <div className="table">
            <table>
              <thead><tr><th>お支払い方法</th><th>請求案内</th><th>手数料</th><th>入金確認の目安</th></tr></thead>
              <tbody>
                <tr><td>クレジットカード</td><td>メール</td><td>無料</td><td>お試し期間終了時点</td></tr>
                <tr><td>銀行振込※1</td><td>メール</td><td>振込手数料お客様負担</td><td>1～2営業日</td></tr>
                <tr><td>請求書払い※2</td><td>請求書郵送</td><td>請求書発行手数料 330円</td><td>1～2営業日</td></tr>
                <tr><td>自動口座振替※3</td><td>―</td><td>無料</td><td>―</td></tr>
              </tbody>
            </table>
            <p>※1 銀行振込にてお支払いいただく場合、銀行振込手数料はお客様のご負担です。</p>
            <p>※2 請求書発行手数料として別途330円が必要です。</p>
            <p>※3 自動口座振替をご希望の場合は、初回2ヶ月分はご請求書での銀行振り込みでお支払いいただきます。</p>
          </div>
          <div className="card">
            <h4>提携クレジットカード一覧</h4>
            <ul>
              <li><img src={visa} alt="visa" /></li>
              <li><img src={master} alt="master" /></li>
              <li><img src={jcb} alt="jcb" /></li>
              <li><img src={amex} alt="amex" /></li>
              <li><img src={diners} alt="diners" /></li>
              <li><img src={discover} alt="discover" /></li>
            </ul>
          </div>

          <div className="notice">
            <h3>お試し期間中のお申し込みキャンセルについて</h3>
            <p className="">※キャンセル手続きは、お問い合わせフォームよりご連絡ください。</p>
            <p>※キャンセル手続きがない場合は、自動的に本登録になります。なお、すでにお支払い済の場合はキャンセルできません。</p>
            <p>※振り込みがない場合は、こちらでキャンセル処理いたします。</p>
            {/* <p><span className="caution">※解約方法はよくある質問に記載</span></p> */}
          </div>
        </section>
			</div>
		</section>
	);
};

export default Flow;
