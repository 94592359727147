import React, { useState } from 'react'



export const Question = ({ index, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <li className={isOpen ? "faq_wrap is_open" : "faq_wrap"}>
      <div className="q_wrap" onClick={handleOpen}>
        <span className="index font_osw">Q{index}</span>
        <p className="question">{question}</p>
      </div>
      <p className="answer" dangerouslySetInnerHTML={{__html: answer}} />
    </li>
  )
}

