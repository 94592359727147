import React, {useRef, useEffect} from 'react'
import "./Plan.scss";
import { Link, useLocation } from 'react-router-dom';

import Bg from "../../img/bg_scale.png"
import { ReactComponent as Arrow } from "../../img/arrow.svg";

import { useOnScreen } from '../../hooks/useOnScreen';
import Scaleup from "../../img/scaleup.png"


const data = [
  {
    plan: "Minプラン",
    price: "10,000",
    target: "フリーランスの方に",
    cost: "100,000円",
    campaign: "30,000円",
    storage: "50GB",
    project: "無制限",
    user: "1"
  },
  {
    plan: "Proプラン",
    price: "30,000",
    target: "ユーザー数無制限に",
    cost: "100,000円",
    campaign: "30,000円",
    storage: "100GB",
    project: "無制限",
    user: "無制限"
  },
]


const Plan = () => {
  const [addToRefs] = useOnScreen()
  const elm = useRef(null);
  const location = useLocation()

  useEffect(() => {
    if (location.hash === "#sec_plan") {
      elm.current.scrollIntoView();
    }
  }, [])
  
  return (
    <section className="sec_plan" ref={elm}>
			<div className="inner">
        <h2 className="sec_title" id="sec_plan">料金プラン</h2>
        <div className="flex_wrap">
          {data.map((d, index) => (
            <div className="card" key={index} ref={addToRefs}>
              <div className="head">
                <h3>{d.plan}</h3>
                <h4>{d.price}<span className="small">円 + 税/月</span></h4>
                <p className="target">{d.target}</p>
              </div>
              <ul>
                <li><span className="label">初期費用</span>
                  <p className="text_grades"><span className="line_through">{d.cost}</span> → <span>{d.campaign}</span></p>
                </li>
                <li><span className="label">容量</span><p>{d.storage}</p></li>
                <li><span className="label">プロジェクト数</span><p>{d.project}</p></li>
                <li><span className="label">ユーザー数</span><p>{d.user}</p></li>
              </ul>
            </div>
          ))}
        </div>

        <div className="scale_wrap">
          <div className="flex_wrap">
            <div className="text">
              <span className="sub01">Scale up</span>
              <h3 className="title01 text_grade">スケールアップ</h3>
              <p>容量の追加やサーバのスペックアップにも対応いたします。<br/>お気軽にお問い合わせください。</p>
            </div>
            <div className="img"><img src={Scaleup} alt=""/></div>
          </div>
        </div>
      <div className="page_link"><Link to="/flow">ご利用までの流れはこちら<Arrow /></Link></div>
      </div>
      <div className="bg">
        <img src={Bg} alt="" />
      </div>
		</section>
  )
}

export default Plan
