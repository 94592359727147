import React, { useEffect } from "react";
import { useForm } from "../../hooks/useForm";
import "./Form.scss";
import { Link } from "react-router-dom";
import Lottie from "react-lottie"
import animationData from "./6012-check.json"
import ReCAPTCHA from "react-google-recaptcha";



const Form = () => {
	const { handleChange, handleSubmit, values, errors, isDisabled, checkIsDisabled, handleRecaptcha, expiredRecaptcha, recaptchaRef } = useForm();

	const defaultOptions = {
    loop: false,
    autoplay: true,
		animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
	};
	
	useEffect(() => {
		checkIsDisabled()
	},[values, checkIsDisabled])

	return (
		<section className="sec_form">
			<div className="inner">
				<h2 className="sec_title" id="sec_form">お問い合わせ</h2>

				<form className="c_form" onSubmit={handleSubmit} method="POST" encType="multipart/form-data" >
					<div className="input">
						<ul>
							<li className="list_type">
								<div className="flex">
									<label htmlFor="purpose">種別</label>
									<label className="radio">
										<input
											type="radio"
											id="type"
											value="資料請求"
											onChange={handleChange}
											checked={values.type === "資料請求"}
										/>資料請求
									</label>
									<label className="radio">
										<input
											type="radio"
											id="type"
											value="お申し込み"
											onChange={handleChange}
											checked={values.type === "お申し込み"}
										/>お申し込み
									</label>
									<label className="radio">
										<input
											type="radio"
											id="type"
											value="お問い合わせ"
											onChange={handleChange}
											checked={values.type === "お問い合わせ"}
										/>お問い合わせ
									</label>
								</div>
							</li>
							<li className="list_name">
								<div className="flex">
									<label htmlFor="name">名前<span className="mark_require">※</span></label>
									<input
										type="text"
										id="name"
										onChange={handleChange}
										value={values.name}
										required
									/>
								</div>
								{(!errors.name && values.name) && <div className="lottie"><Lottie options={defaultOptions} speed={2} /></div>}
								<p className="error_text">{errors.name}</p>
							</li>
							<li className="list_email">
								<div className="flex">
									<label htmlFor="email">メールアドレス<span className="mark_require">※</span></label>
									<input
										type="email"
										id="email"
										onChange={handleChange}
										value={values.email}
										required
									/>
								</div>
								{(!errors.email && values.email) && <div className="lottie"><Lottie options={defaultOptions} speed={2} /></div>}
								<p className="error_text">{errors.email}</p>
							</li>
							<li className="list_tel">
								<div className="flex">
									<label htmlFor="tel">電話番号<span className="mark_require">※</span></label>
									<input
										type="tel"
										id="tel"
										onChange={handleChange}
										value={values.tel}
										required
									/>
								</div>
								{(!errors.tel && values.tel) && <div className="lottie"><Lottie options={defaultOptions} speed={2} /></div>}
								<p className="error_text">{errors.tel}</p>
							</li>
							{/* <li className="list_plan">
								<div className="flex">
									<label htmlFor="plan">プラン</label>
									<select
										id="plan"
										onChange={handleChange}
									>
										<option value="ー">ー</option>
										<option value="Minプラン">Minプラン</option>
										<option value="Proプラン">Proプラン</option>
										<option value="スケールアップ">スケールアップ</option>
									</select>
								</div>
							</li> */}
							<li className="list_company">
								<div className="flex">
									<label htmlFor="company">会社名</label>
									<input
										type="text"
										id="company"
										onChange={handleChange}
										value={values.company}
									/>
								</div>
							</li>
							<li className="list_content">
								<div className="flex">
									<label htmlFor="content">お問い合わせ内容<span className="mark_require">※</span></label>
									<textarea
										id="content"
										onChange={handleChange}
										value={values.content}
										required
									></textarea>
								</div>
								{(!errors.content && values.content) && <div className="lottie"><Lottie options={defaultOptions} speed={2} /></div>}
								<p className="error_text">{errors.content}</p>
							</li>
						</ul>

						<div className="check_wrap">
							<label className="radio">
								<input
									type="checkbox"
									id="privacy"
									onChange={handleChange}
									value={values.privacy}
									required
								/><Link to="/privacy" target="_blank">個人情報の取り扱い</Link>に同意する
							</label>
						</div>
						<div className="recaptcha">
							<ReCAPTCHA
								id="recaptcha"
								ref={recaptchaRef}
								sitekey={process.env.REACT_APP_RECAPTCHA}
								onChange={handleRecaptcha}
								onExpired={expiredRecaptcha}
								onErrored={expiredRecaptcha}
							/>
						</div>
						<button className={isDisabled ? "btn_cta disabled" : "btn_cta"} onClick={handleSubmit} disabled={isDisabled}>送信</button>
					</div>
				</form>
			</div>
		</section>
	);
};

export default Form;
