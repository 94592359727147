import React, {useState} from "react";
import "./MainVisual.scss";
import { Link } from "react-router-dom";
import MainImage from "../../img/mv.png";
import { HashLink } from "react-router-hash-link";


const MainVisual = () => {
  const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const scrollWidthOffset = (el) => {
		const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
		const yOffset = -100;
		window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
	};

  
	return (
		<section className="sec_mv">
			<div className="text_wrap">
				<h2 className="text_grade">Web制作に必須のテスト環境を<br className="pc" />最速で構築</h2>
				<p>サーバの専門的知識がなくても、Web制作におけるテスト環境の構築、<br className="pc" />Gitを使ったバージョン管理、Gitからのデプロイを<br className="pc" />まとめて簡単に行えるサービスです。</p>
				<HashLink
          className="btn_cta"
          to="/#sec_form"
          scroll={(el) => scrollWidthOffset(el)}
          onClick={handleClick}
					// to="//development.ap.scrw.jp/"
					// target="_blank"
					// rel="noopener noreferrer"
				>お申し込み
				</HashLink>
			</div>
			<div className="img_wrap">
				<img src={MainImage} alt="" />
			</div>
		</section>
	);
};

export default MainVisual;
