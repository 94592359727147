import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FlowComp } from "../components";

const Flow = () => {
  useEffect(() => {
    window.gtag("config", process.env.TRACKING_ID, { page_path: "/flow" });
  }, [])
	return (
		<>
			<Helmet>
				<title>ご利用までの流れ | Screew | Web制作のプロジェクト進行をスムーズにするツール</title>
			</Helmet>
			<FlowComp />
		</>
	);
};

export default Flow;
