import React, { useEffect } from "react";
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom";
import { useOnScreen } from "../hooks/useOnScreen";
import { ReactComponent as Mail } from "../img/mail.svg";


const Thanks = () => {
  const [addToRefs] = useOnScreen();
  useEffect(() => {
    window.gtag("config", process.env.TRACKING_ID, { page_path: "/thanks" });
  }, [])

	return (
		<>
			<Helmet>
				<title>お問い合わせ完了 | Screew | Web制作のプロジェクト進行をスムーズにするツール</title>
			</Helmet>
			<section className="p_thanks">
				<div className="inner" ref={addToRefs}>
					<h1 className="sec_title">送信完了</h1>
					<div className="icon"><Mail /></div>
					<div className="body">
					<p>この度はお問い合わせいただきありがとうございます。<br/>ご記入いただいた内容を送信いたしました。<br/>お問い合わせ内容を確認後、ご連絡を差し上げます。</p>
					</div>
					<Link className="btn_cta" to="/">トップページへ戻る</Link>
				</div>
				</section>
			</>
	);
};

export default Thanks;
